import { Defs, RadialGradient, Rect, Stop } from "react-native-svg";

const strokeWidth = 3;

const SvgSilverBorder = ({ borderRadius }: { borderRadius: number }) => {
	return (
		<>
			<Defs>
				<RadialGradient
					id="borderGrad"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(157 56) scale(288 191)"
				>
					<Stop offset="0.01" stopColor="#4F4F4F" />
					<Stop offset="0.06" stopColor="#8E8E8E" />
					<Stop offset="0.13" stopColor="#C0C0C0" />
					<Stop offset="0.18" stopColor="#CBCBCB" />
					<Stop offset="0.23" stopColor="#D8D8D8" />
					<Stop offset="0.34" stopColor="#878787" />
					<Stop offset="0.48" stopColor="#B7B7B7" />
					<Stop offset="0.67" stopColor="#878787" />
					<Stop offset="0.85" stopColor="#FAFAFA" />
					<Stop offset="0.9" stopColor="#878787" />
					<Stop offset="1" stopColor="#4F4F4F" />
				</RadialGradient>
			</Defs>
			<Rect
				x="0"
				y="0"
				width="100%"
				height="100%"
				rx={borderRadius}
				ry={borderRadius}
				fill="none"
				stroke="url(#borderGrad)"
				strokeWidth={strokeWidth}
			/>
		</>
	);
};
export default SvgSilverBorder;
