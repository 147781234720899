import { memo, useState } from "react";
import { View } from "react-native";
import config from "src/config";
import { Image } from "src/swsh-native";
import { NoiseProps } from "./types";

const Noise = ({ dim: dimProp, style, opacity = 0.5 }: NoiseProps) => {
	const [dim, setDim] = useState({ width: 0, height: 0 });
	const { width, height } = dimProp ?? dim;

	return (
		<View
			pointerEvents="none"
			style={[style]}
			onLayout={
				dimProp
					? undefined
					: (event) => {
							const { width, height } = event.nativeEvent.layout;
							setDim({ width: Math.round(width), height: Math.round(height) });
						}
			}
		>
			{width > 0 && height > 0 && (
				<Image
					alt={""}
					pointerEvents="none"
					src={`${config.resources.publicUrl}/asset/noise/cache/v0/${width}/${height}/${opacity}/png`}
					dim={{
						width,
						height,
					}}
				/>
			)}
		</View>
	);
};
export default memo(Noise);
