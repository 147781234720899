import { TouchableOpacity } from "react-native-gesture-handler";
import { TouchableWrapperProps } from "../types";

const BaseTouchableWrapper = ({
	onLayout,
	onPress,
	disabled,
	loading,
	style,
	containerStyle,
	children,
}: TouchableWrapperProps) => {
	return (
		<TouchableOpacity
			onLayout={onLayout}
			onPress={onPress}
			disabled={disabled || loading}
			style={style}
			containerStyle={containerStyle}
		>
			{children}
		</TouchableOpacity>
	);
};
export default BaseTouchableWrapper;
