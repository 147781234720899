import { useState } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { Svg } from "react-native-svg";
import Noise from "src/components/Noise/Noise";
import SvgSilverBorder from "./SvgSilverBorder";

const HollowBackground = ({
	borderRadius,
	containerStyle,
}: {
	borderRadius: number;
	containerStyle?: StyleProp<ViewStyle>;
}) => {
	const [dim, setDim] = useState({ width: 0, height: 0 });
	const { width, height } = dim;

	return (
		<View
			onLayout={(event) => {
				const { width, height } = event.nativeEvent.layout;
				setDim({ width, height });
			}}
			style={[
				{
					position: "absolute",
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					overflow: "hidden",
				},
				containerStyle,
				{
					borderRadius,
				},
			]}
		>
			{!!(width && height) && (
				<Svg viewBox={`0 0 ${width} ${height}`}>
					<SvgSilverBorder borderRadius={borderRadius} />
				</Svg>
			)}
			<Noise
				style={{
					position: "absolute",
					width: "100%",
					height: "100%",
				}}
				opacity={0.1}
			/>
		</View>
	);
};
export default HollowBackground;
