import { track } from "@amplitude/analytics-react-native";
import { useCallback } from "react";
import { View } from "react-native";
import { amp } from "src/api/amplitude";
import cursor from "src/constants/cursor";
import extractDomainName from "src/helpers/extractDomainName";
import { Link } from "src/swsh-native";
import { TouchableWrapperProps } from "../../types";

const LinkWrapper = ({
	onLayout,
	containerStyle,
	style,
	href,
	target,
	children,
	disabled,
	loading,
	onPress,
}: TouchableWrapperProps) => {
	const handleClick = useCallback(() => {
		track(amp.link.click, {
			href,
			domainName: extractDomainName(href),
		});
		onPress?.();
	}, [href, onPress]);

	return (
		<View onLayout={onLayout} style={containerStyle}>
			<Link
				href={loading || disabled ? "/" : (href ?? "/")}
				target={target}
				onPress={handleClick}
				style={[
					style,
					{
						cursor: loading || disabled ? cursor.notAllowed : cursor.pointer,
						pointerEvents: loading || disabled ? "none" : "auto",
					},
				]}
			>
				{children}
			</Link>
		</View>
	);
};
export default LinkWrapper;
